.button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.button:not([disabled]):hover {
  cursor: pointer;
  background: #9c65a7;
}

.button {
  border: 1px solid #4f005f;
  background: #4f005f;
  color: white;
  padding: 0.75rem 1.5rem;
  margin: 0 0.5rem;
  font-size: 1.15rem;
}

.buttonWrapper {
  /* margin: 50px 10px; */
  position: fixed;
  top: 80%;
  left: 50%;
  width: 400px;
  height: 200px;
  margin-top: -100px;
  margin-left: -400px;
}

.forwardButton {
}

.prevButton {
}
