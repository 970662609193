.card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-left: auto;
  margin-top: 30px;
}

.card > form {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 2px solid salmon;
  padding: 10px;
}

.card > input,
select {
  margin: 10px 0px;
}
