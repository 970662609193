.loggerContainer {
  border: 1px solid lightgrey;
  width: 400px;
  margin-left: auto;
  border: 2px solid #ccc;
  background-color: lightgrey;
  box-sizing: border-box;
  overflow: scroll;
}

.loggerBox h4 {
  text-decoration: underline;
  letter-spacing: 1.25px;
  text-align: left;
  margin-left: 20px;
}

.loggerBox blockquote {
  margin-top: 0px;
}

.loggerBox,
.config-box {
  word-wrap: break-word;
}

.loggerBox ul {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.loggerBox li {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}
